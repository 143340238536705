.sidebar_xyz123 {
    width: 60px;
    height: 100vh;
    background-color: #282c34;
    transition: width 0.3s ease;
    overflow-x: hidden;
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.sidebar_xyz123:hover {
    width: 200px;
}

.sidebar-menu_xyz123 {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.sidebar-menu-item_xyz123 {
    width: 100%;
    color: #ffffff;
    padding: 15px 20px; /* Increased padding for more vertical space */
    cursor: pointer;
    transition: background-color 0.2s ease;
    overflow: hidden; /* Ensure submenu doesn't affect layout */
}

.sidebar-item-container_xyz123 {
    display: flex;
    align-items: center; /* Center icon and text vertically */
    width: 100%; /* Ensure the container takes full width */
}

.sidebar-icon_xyz123 {
    font-size: 24px;
    flex-shrink: 0;
    transition: margin-right 0.3s ease;
}

.sidebar-text_xyz123 {
    opacity: 0;
    display: none; /* Hide text when sidebar is collapsed */
    white-space: nowrap;
    transition: opacity 0.3s ease, margin-left 0.3s ease;
}

.sidebar_xyz123:hover .sidebar-item-container_xyz123 .sidebar-text_xyz123 {
    display: inline-block;
    opacity: 1;
    margin-left: 10px;
}

.sidebar-menu-item_xyz123:hover {
    background-color: #3a3f47;
}

.sidebar-submenu_xyz123 {
    list-style-type: none;
    padding-left: 30px; /* Indent submenu items */
    margin-top: 5px;
    display: none; /* Hide submenu when sidebar is collapsed */
}

.sidebar-submenu-item_xyz123 {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.sidebar-submenu-label_xyz123 {
    margin-left: 5px;
    color: #ffffff;
    font-size: 14px;
    white-space: nowrap;
}

.sidebar_xyz123:hover .sidebar-submenu_xyz123 {
    display: block; /* Show submenu when sidebar is expanded */
}

/* --------------------------------- */
/* PanelBarraOrderComponent Styles   */
/* --------------------------------- */
.panel-barra-order-container {
    position: relative; /* Enable positioning for the buttons */
    border: 1px solid #3a3f47; /* Softer border color matching the sidebar */
    border-radius: 8px;
    background-color: #2c2f33; /* Dark background to match sidebar */
    padding: 15px;
    transition: background-color 0.3s ease;
}

.panel-barra-order-container:hover {
    background-color: #3a3f47; /* Slightly lighter on hover */
}

.panel-barra-order-title,
.panel-barra-order-table,
.panel-barra-order-items-title,
.panel-barra-order-observations-title {
    font-weight: 600;
    color: #ffffff; /* White text color */
    font-size: 14px;
}

.panel-barra-order-product {
    font-weight: 500;
    color: #d1d1d1; /* Slightly lighter text for product details */
    font-size: 14px;
}

.panel-barra-order-variant {
    font-size: 12px;
    color: #a1a1a1; /* Even lighter text for variants */
}

.panel-barra-order-observations {
    font-size: 14px;
    color: #d1d1d1;
}

.deliver-button,
.print-button {
    position: absolute; /* Absolute positioning */
    top: -10px; /* Move buttons slightly above the container */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    z-index: 1; /* Ensure buttons appear above the container */
}

.deliver-button {
    left: 50%; /* Center the button horizontally */
    transform: translateX(-32.5px); /* Offset to the left of the center */
    background-color: #28a745; /* Green for deliver */
}

.deliver-button:hover {
    background-color: #218838; /* Darken the button on hover */
}

.print-button {
    left: 50%; /* Center the button horizontally */
    transform: translateX(10px); /* Offset to the right of the center */
    background-color: #007bff; /* Blue for print */
}

.print-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
}

.print-button i,
.deliver-button i {
    font-size: 16px;
}

/* --------------------------------- */
/* NEW: Stacked "Evento" label + select, hidden until hover */
/* --------------------------------- */
.sidebar-event-container_xyz123 {
    display: none;
    opacity: 0;
    flex-direction: column; /* stack label above select */
    margin-left: 0;         /* keep it aligned */
    margin-top: 8px;        /* space above select */
    transition: opacity 0.3s ease, margin-left 0.3s ease;
}

.sidebar_xyz123:hover .sidebar-event-container_xyz123 {
    display: flex;
    opacity: 1;
    margin-left: 10px; /* shifts container a bit right when expanded */
}

.home-container {
    position: relative; /* ADDED - ensures absolutely positioned elements can anchor here */
    height: 100vh;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.pos-order-interface {
    background-color: #f8f9fa;
    height: 100vh;
    overflow: hidden;
}

.pos-order-list-interface {
    background-color: #f8f9fa;
    overflow: hidden;
    height: 100vh;
}

.pos-order-summary {
    background-color: white;
    height: calc(100vh - 60px);
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.pos-order-summary-title {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem;
    height: 60px;
}

.pos-table-selector-container {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: .5rem 1rem;
    background-color: rgba(222, 226, 230, 0.7);
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.pos-table-selector-container.disabled {
    background-color: rgba(222, 226, 230, 0.5);
    cursor: not-allowed;
}

.pos-order-summary-total {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem;
}

.pos-order-summary-total p {
    margin: 0;
    font-size: 1.2rem;
    opacity: 0.5;
}

.pos-order-items-container {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #f8f9fa;
    border-top: 2.5px solid rgba(222, 226, 230, 0.5);
    border-bottom: 3px solid rgba(222, 226, 230, 0.9);
    padding: 0 !important;
    width: 100%;
}

.pos-order-item {
    cursor: pointer;
}

.pos-order-item-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pos-order-item.selected {
    background-color: #d3d3d3;
}

.pos-order-category-container {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    background-color: rgba(222, 226, 230, 0.5);
    opacity: 0.5;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.pos-order-category-container.selected {
    background-color: #d3d3d3;
    opacity: 0.8;
}

.pos-order-category {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    opacity: 0.5;
    text-transform: uppercase;
}

.pos-order-item-quantity-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 1rem;
    font-size: 20px;
    background-color: rgba(216, 216, 216, 0.3);
    border-radius: 3px;
    font-weight: bold;
    width: 90% !important;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
}

.pos-order-item-title {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
}

.pos-order-item-variant {
    font-family: Lato, sans-serif;
    font-size: 0.8rem;
    margin: 0;
}

.pos-order-item-price {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
}

.pos-order-item-total-price {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    margin: 0;
}

.pos-order-summary-discount-button {
    background-color: white;
    color: #1976d2;
    font-family: Lato, sans-serif;
    font-weight: 900;
    padding: 1rem;
    border-radius: .5rem;
    width: 100%;
    border: 1px solid #1976d2;
    font-size: 1.1rem;
}

.pos-order-summary-discount-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pos-order-summary-void-button {
    background-color: white;
    color: #d32f2f;
    font-family: Lato, sans-serif;
    font-weight: 900;
    padding: 1rem;
    border-radius: .5rem;
    width: 100%;
    border: 1px solid #d32f2f;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pos-order-summary-void-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pos-order-summary-total-button {
    background-color: #1976d2;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 1.1rem;
    font-weight: 900;
    padding: 1rem;
    border-radius: .5rem;
    width: 100%;
    border: none;
}

.pos-order-summary-total-button:hover {
    cursor: pointer;
}

.pos-order-summary-total-button:disabled {
    background-color: #1976d2;
    opacity: 0.5;
}

.pos-order-summary-total-button:disabled:hover {
    cursor: not-allowed;
}

.pos-payment-method-container {
    background-color: white;
    height: 120px;
    width: 180px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pos-payment-method-icon {
    height: 40px;
    width: 40px;
    object-fit: cover;
}

.pos-payment-method-title {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    font-weight: 900;
    margin: 0;
}

.pos-payment-method-button {
    background-color: transparent;
    border: none;
}

.pos-payment-button-activated {
    border: 3px solid rgba(25, 118, 210, 0.5);
}

.counter-container {
    display: flex;
    justify-content: space-between;
}

.counter {
    background-color: #d8d8d8;
    height: 80px;
    border-radius: 5px;
    display: flex;
    width: 60px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.cancel-button-pos-order {
    background-color: #e2e2e2;
    height: 80px;
    border-radius: 5px;
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: center;
}

.done-button-pos-order-active {
    background-color: #1976d2;
}

.done-button-pos-order-disabled {
    background-color: #1976d2;
    opacity: 0.5;
}

.pos-option-box {
    flex: 0 1 calc(33.333% - 20px);
    background-color: #ccc;
    border-radius: 15px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pos-option-box:hover {
    cursor: pointer;
    background-color: #ddd;
}

.pos-option-box-selected {
    background-color: #ddd;
    border: 3px solid #d3d3d3;
}

.pos-icon {
    color: #333333;
    display: block;
    margin: 0 auto;
}

.pos-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: #333333;
    line-height: 1.2;
    max-height: 2.7em;
    box-sizing: border-box;
}

.pos-text-item {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: #333333;
    line-height: 1.2;
    max-height: 5.4em;
    box-sizing: border-box;
    font-weight: bold;
}

.dialog-content-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px;
    background-color: white;
}

.search-icon {
    margin-right: 5px;
    margin-left: 3px;
    color: #ccc;
}

input[type="text"] {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 5px;
}

.pos-parent-container {
    padding: 0 10px;
    padding-top: 40px;
}

.pos-order-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.options-big-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0;
    list-style-type: none;
    width: 100%;
    background-color: white;
    border-radius: 10rem;
    border: 1px solid #C8C8C8;
}

.options-big-icon {
    color: #333333;
    display: flex;
    padding: 10px 10px;
    height: 100%;
    border-radius: 5rem;
    justify-content: center;
    align-items: center;
}

.options-big-icon:hover {
    cursor: pointer;
}

.options-big-icon-selected {
    background-color: #C8C8C8;
}

.options-big-icon-number {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f8f9fa;
    width: 25px;
    height: 25px;
    font-size: 10px;
    font-weight: bold;
    overflow: hidden;
}

.pos-order-container {
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #C8C8C8;
}

.pos-order-container-selected {
    background-color: rgba(200, 200, 200, 0.6);
    border: 1px solid #666666;
}

.pos-order-container:hover {
    cursor: pointer;
}

.pos-order-container-selected:hover {
    cursor: pointer;
    background-color: rgba(200, 200, 200, 0.6);
}

.option-top-bar {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    background-color: #d3d3d3;
    margin: 0 7px;
    cursor: pointer;
    text-align: center;
    border: none;
}

/* Button for Cambiar Usuario, top-right */
.change-user-button {
    position: absolute; /* or 'fixed' if you want top-right of the entire screen */
    top: 20px;
    right: 20px;
    z-index: 9999;
}

.change-user-button:hover {
    background-color: #c3c3c3;
}

.cash-register-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    background-color: #d3d3d3;
    overflow: hidden;
}

.cash-register-options {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .cash-register-options {
        height: 25vh;
    }
    .cash-register-details {
        height: calc(100vh - 25vh);
        overflow-y: scroll;
    }
}

.cash-register-details {
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.sticky-th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
}
.scrollable-tbody {
    display: block;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
}
.table-fixed {
    width: 100%;
    table-layout: fixed;
}

.scrollable-container {
    height: 200px;
}

@media (max-width: 768px) {
    .scrollable-container {
        overflow-y: scroll;
    }
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #d3d3d3;
    border: none;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
}

.payment-inputs-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: end;
    margin-bottom: 10px;
    gap: 10px;
}

.payment-input-container {
    display: flex;
    flex-direction: column;
}

.payment-input {
    background: transparent; /* Makes the background color transparent */
    border: none; /* Removes all borders */
    outline: none; /* Removes the outline shown when the input is focused */
    font-weight: bold;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.payment-input:read-only {
    color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
}

.payment-input:disabled {
    cursor: not-allowed;
}

.payment-top-row-container {
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #C8C8C8;
}

.payment-top-row-container-disabled {
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    height: 40px;
    background-color: #C8C8C8;
}


.key-buttons-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.key-button-container {
    aspect-ratio: 1 / 1; /* Ensures the element is always square */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D3D3D3;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14pt;
}

.key-class-10000, .key-class-20000, .key-class-50000 {
    background-color: #C8C8C8;
}

.key-button-container.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.payment-method-container-terminal {
    background-color: #C8C8C8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    padding: 20px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
}

.payment-method-container-terminal.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.payment-button-terminal {
    background-color: #1976d2;
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    border-radius: 0.5rem;
    border: none;
    padding: 20px 10px;
    width: 100%;
    font-size: 1.1rem;
}

.payment-button-new-order {
    background-color: #708090;
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    border-radius: 0.5rem;
    border: none;
    padding: 20px 10px;
    width: 100%;
    font-size: 1.1rem;
}

.payment-button-terminal:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.payment-method-icon-terminal {
    width: 25px;
    height: 25px;
}

.payment-method-container-terminal.selected {
    background-color: rgba(25, 118, 210, 0.5);
}
.password-container {
    max-width: 500px; /* Ensure the container does not overflow the screen */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add some padding around the keyboard for spacing */
}

.passcode-display {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.passcode-dot {
    width: 24px; /* Width of the dot */
    height: 24px; /* Height of the dot */
    margin: 0 4px;
    font-size: 50px; /* Size of the dot */
    line-height: 0px; /* Ensures the dot is centered vertically */
}

.key-password-buttons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Maintain a 3-column layout */
    gap: 10px;
    margin: 0; /* Center the grid horizontally */
}

.key-password-button-container {
    aspect-ratio: 4 / 3; /* Ensures the element is always square */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D3D3D3;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px; /* Adjust font size as needed */
    padding: 10px; /* Adjust padding to control button size */
    transition: background-color 0.3s; /* Add a smooth transition effect */
    border: none; /* Remove the border */
}

.key-password-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px; /* Width of the button */
    height: 60px; /* Height of the button, keep it equal to width for a square */
}

.password-submit-button {
    width: 100%; /* Ensure the button takes up the full width of the container */
    height: 60px; /* Height of the button */
    margin-top: 10px; /* Add some spacing between the buttons */
    border-radius: 5px; /* Add rounded corners to the button */
    background-color: #1976d2; /* Set the background color to blue */
    color: white; /* Set the text color to white */
    font-weight: bold; /* Make the text bold */
    cursor: pointer; /* Add a pointer cursor on hover */
    transition: background-color 0.3s; /* Add a smooth transition effect */
    border: none; /* Remove the border */
}

.password-submit-button.disabled {
    opacity: 0.5; /* Reduce the opacity to indicate the button is disabled */
    cursor: not-allowed; /* Change the cursor to not-allowed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .passcode-dot {
        margin: 0 2px;
    }

    .key-password-button {
        width: 50px; /* Smaller width for smaller screens */
        height: 50px; /* Smaller height for smaller screens */
    }
}
